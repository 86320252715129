import React, { ReactNode, useState } from "react";
import classes from "./styles.module.scss";
export interface ButtonProps {
  onClick?: () => void;
  size?: "small" | "medium" | "large" | "savebtn";
  variant?: "primary" | "previous" | "secondary" | "none" | "mapRefresh";
  reverse?: boolean;
  className?: string;
  style?: React.CSSProperties;
  disable?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  children?: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  id?: string;
  title?: string;
  tooltip?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  const [keyCount, setKeyCount] = useState(0);

  const handleClick = (event: any) => {
    if (event && event.detail <= 1) {
      if (props?.onClick) {
        // @ts-ignore
        props.onClick(event);
      }
    } else {
      event?.preventDefault();
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.repeat) return;
    const { key } = event;
    if (key === "Enter") {
      if (keyCount < 1) {
        event.currentTarget.click();
        // if (props?.onKeyDown) {
        //   props.onKeyDown(event);
        // }

        setKeyCount((prevCount: number) => prevCount + 1);
        setTimeout(() => {
          setKeyCount(0);
        }, 500);
      } else {
        event.preventDefault();
      }
    }
  };

  return (
    <button
      id={props.id}
      disabled={props.disable}
      type={props.type || "button"}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={`inline-flex gap-x-2 items-center whitespace-nowrap justify-center leading-150 !disabled:text-[#000000] ${
        props.reverse ? "flex-row-reverse" : ""
      } ${classes[props.size || "medium"]} ${classes[props.variant || "primary"]} ${
        props.className || ""
      }`}
      style={props.style}
      title={props.title}
      data-tip={props.tooltip}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  className: undefined,
  size: undefined,
  variant: undefined,
  reverse: undefined,
  style: undefined,
  disable: undefined,
  type: undefined,
  children: undefined,
  id: undefined,
  onClick: () => null,
  title: undefined,
  tooltip: undefined,
};

export default Button;
