import { TextField } from "@mui/material";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";
export interface InputPropsTypes {
  className?: string;
  id: string;
  placeholder?: string;
  value?: string | number;
  type?: string;
  defaultValue?: string;
  focused?: boolean;
  disabled?: boolean;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?(e?: any): void;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  label?: string;
  ref?: any;
  inputProps?: {
    startAdornment?: JSX.Element;
    endAdornment?: JSX.Element;
    inputProps?: {
      accept?: string;
    };
  };
  helperText?: string;
  multiline?: boolean;
  rows?: number | string;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  shrink?: boolean;
}

const Input: React.FC<InputPropsTypes> = (props) => (
  <div className="relative">
    <TextField
      id={props.id}
      name={props.id}
      type={props.type}
      focused={props.focused}
      label={props.label}
      InputLabelProps={{
        shrink: props.shrink,
      }}
      variant="outlined"
      value={props.value}
      autoComplete="off"
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      className={`${props.className}`}
      disabled={props.disabled}
      InputProps={props.inputProps}
      helperText={props.helperText}
      multiline={props.multiline}
      rows={props.rows}
      ref={props.ref}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e);
        }
      }}
      onBlur={(e) => {
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
      onKeyDown={(e) => {
        if (props.onKeyDown) {
          props.onKeyDown(e);
        }
      }}
    />
    {props.error && props.touched && (
      <span className="flex items-center font-medium text-error-main text-xs mt-1">
        {props.error as string}
      </span>
    )}
  </div>
);

Input.defaultProps = {
  className: undefined,
  placeholder: undefined,
  label: undefined,
  error: undefined,
  multiline: undefined,
  rows: undefined,
  inputProps: undefined,
  onBlur: () => null,
  onChange: () => null,
  onKeyDown: () => null,
  value: undefined,
  helperText: undefined,
  disabled: undefined,
  type: undefined,
  ref: undefined,
  defaultValue: undefined,
  focused: undefined,
  touched: undefined,
  shrink: undefined,
};

export default Input;
