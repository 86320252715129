/* eslint class-methods-use-this: "off" */

import axios, { RawAxiosRequestConfig } from "axios";
import Gleap from "gleap";
import { clearStorage } from "../utils/general";
import { store } from "../Store/Store";
import { logout } from "../Store/Slices/AuthSlice";
import { setNotificationCount } from "../Store/Slices/MasterSlice";

let interceptor: Interceptors | null = null;
const baseURL = process.env.NEXT_PUBLIC_API_URL;
const baseCubeURL = process.env.NEXT_PUBLIC_CUBE_URL;
class Interceptors {
  constructor() {
    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          Gleap.clearIdentity();
          clearStorage();
          store.dispatch(setNotificationCount(0));
          store.dispatch(logout());
          window.location.href = "/";
        }
        return Promise.reject(error);
      },
    );
  }

  get(url: string | number, config?: RawAxiosRequestConfig<any> | undefined) {
    return axios.get(`${baseURL && baseURL}${url}`, { ...config });
  }

  getFile(url: string | number) {
    return axios.get(`${baseURL && baseURL}${url}`, {
      responseType: "blob",
      headers: {
        "Access-Control-Expose-Headers": "*",
      },
    });
  }

  post(url: string | number, data: any, config?: RawAxiosRequestConfig<any> | undefined) {
    return axios.post(`${baseURL && baseURL}${url}`, data, { ...config });
  }

  put(url: string | number, data: any) {
    return axios.put(`${baseURL && baseURL}${url}`, data);
  }

  deleteWithData(url: string | number, data: any) {
    return axios.delete(`${baseURL && baseURL}${url}`, { data });
  }

  delete(url: string | number, config?: RawAxiosRequestConfig<any> | undefined) {
    return axios.delete(`${baseURL && baseURL}${url}`, { ...config });
  }

  patch(url: string | number, data: any, config?: RawAxiosRequestConfig<any> | undefined) {
    return axios.patch(`${baseURL && baseURL}${url}`, data, { ...config });
  }

  getParams(url: string | number, params: any) {
    return axios(`${baseURL && baseURL}${url}`, {
      params: { ...params },
    });
  }

  // getCube(url: string | number, params: any) {
  //   return axios(`${baseCubeURL && baseCubeURL}${url}`, {
  //     params: { ...params },
  //   });
  // }

  getCube(url: string | number, config?: RawAxiosRequestConfig<any> | undefined) {
    return axios.get(`${baseCubeURL && baseCubeURL}${url}`, { ...config });
  }
}

export const interceptorFunction = () => {
  if (interceptor === null) {
    interceptor = new Interceptors();
  }
  return interceptor;
};
export default Interceptors;
